<template>
  <v-app-bar color="navbar" app>
    <v-app-bar-nav-icon @click.stop="$emit('hide', !show)"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title> -->
    <v-spacer></v-spacer>
    <div class="px-1">
      <v-btn icon color="primary">
        <v-icon>info</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color v-on="on">
            <flag :iso="currentFlag" v-bind:squared="false" />
            <v-icon right color="primary">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(entry, index) in languagess"
            :key="`${index}010`"
            @click="changeLocale(entry)"
          >
            <v-list-item-title>
              <flag :iso="entry.flag" v-bind:squared="false" />
              &nbsp;{{ entry.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <div>
              <v-badge
                color="red"
                :content="notifications"
                bordered
                overlap
                left
                v-if="notifications"
              >
                <v-icon color="primary"> mdi-bell </v-icon>
              </v-badge>
              <v-icon color="primary" v-else> mdi-bell </v-icon>
            </div>
          </v-btn>
        </template>

        <v-card class="mx-auto" max-width="500">
          <v-list>
            <v-list-item>
              <v-list-item-avatar size="40">
                <v-img src="/avatar-whire.png" contain></v-img>
              </v-list-item-avatar>

              <v-list-item-action> </v-list-item-action>
              <v-list-item-title>{{
                "Whirelab".toUpperCase()
              }}</v-list-item-title>
              <v-list-item-action> </v-list-item-action>
            </v-list-item>

            <v-list-item-group
              v-if="true"
              v-model="selected"
              active-class="primary--text"
            >
              <!-- <pre>{{chats}}</pre> -->
              <template v-for="(item, index) in chats">
                <v-skeleton-loader
                  :key="index"
                  type="list-item-avatar"
                  v-if="!loadMessages"
                ></v-skeleton-loader>
                <v-list-item :key="item.name" v-else to="/root/support/chat" dense>
                  <!-- <template> -->

                  <template v-slot:default="{ active }">
                    <v-list-item-avatar >
                      <v-badge
                        bordered
                        bottom
                        color="primary"
                        dot
                        offset-x="15"
                        offset-y="11"
                      >
                        <v-avatar size="35" v-if="item.messages[0].author">
                          <v-img
                            :src="
                              item.messages[0].author.photo != null
                                ? `${apiUrl}/images/user/${item.messages[0].author.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                        <v-avatar size="35" v-else>
                          <v-img
                            :src="
                              item.messages[0].to.photo != null
                                ? `${apiUrl}/images/user/${item.messages[0].to.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          item.messages[0].author
                            ? getUserName(item.messages[0].author.name)
                            : getUserName(item.messages[0].to.name)
                        "
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.messages[item.messages.length - 1].body"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-badge
                        color="red"
                        :content="
                          countReads(item.messages)
                            ? countReads(item.messages)
                            : 0
                        "
                        bordered
                        overlap
                        v-if="countReads(item.messages)"
                      >
                        <v-icon v-if="!active" color="grey"> mdi-chat </v-icon>

                        <v-icon v-else color="grey"> mdi-chat </v-icon>
                      </v-badge>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < chats.length - 1"
                  :key="index"
                ></v-divider>
              </template>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-avatar size="30">
                  <v-img src="/avatar-kenmare.png" contain></v-img>
                </v-list-item-avatar>

                <v-list-item-action> </v-list-item-action>
                <v-list-item-title>{{
                  "Kenmare".toUpperCase()
                }}</v-list-item-title>
                <v-list-item-action> </v-list-item-action>
              </v-list-item>

              <v-list-item-group active-class="primary--text">
                <template v-for="(item, index) in chatsKenmare">
                  <v-skeleton-loader
                    :key="index"
                    type="list-item-avatar"
                    v-if="!loadMessages"
                  ></v-skeleton-loader>
                  <v-list-item
                    :key="item.name"
                    v-else
                    to="/root/support/kenmare"
                    dense
                  >
                    <!-- <template> -->

                    <template v-slot:default="{ active }">
                      <v-list-item-avatar>
                        <v-badge
                          bordered
                          bottom
                          color="primary"
                          dot
                          offset-x="15"
                          offset-y="11"
                        >
                          <v-avatar size="35" v-if="item.messages[0].author">
                            <v-img
                              :src="
                                item.messages[0].author.photo != null
                                  ? `${apiUrlKen}/images/user/${item.messages[0].author.photo}`
                                  : '/profile.png'
                              "
                            ></v-img>
                          </v-avatar>
                          <v-avatar size="35" v-else>
                            <v-img
                              :src="
                                item.messages[0].to.photo != null
                                  ? `${apiUrlKen}/images/user/${item.messages[0].to.photo}`
                                  : '/profile.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            item.messages[0].author
                              ? getUserName(item.messages[0].author.name)
                              : getUserName(item.messages[0].to.name)
                          "
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.messages[item.messages.length - 1].body"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-badge
                          color="red"
                          :content="
                            countReads(item.messages)
                              ? countReads(item.messages)
                              : 0
                          "
                          bordered
                          overlap
                          v-if="countReads(item.messages)"
                        >
                          <v-icon v-if="!active" color="grey">
                            mdi-chat
                          </v-icon>

                          <v-icon v-else color="grey"> mdi-chat </v-icon>
                        </v-badge>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < chatsKenmare.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>

              <v-list-item>
                <v-list-item-avatar size="30">
                  <v-img src="/logoAmer.svg" contain></v-img>
                </v-list-item-avatar>

                <v-list-item-action> </v-list-item-action>
                <v-list-item-title>Amer</v-list-item-title>
                <v-list-item-action> </v-list-item-action>
              </v-list-item>
              <v-list-item-group active-class="primary--text">
                <template v-for="(item, index) in chatsAmer">
                  <v-skeleton-loader
                    :key="index"
                    type="list-item-avatar"
                    v-if="!loadMessages"
                  ></v-skeleton-loader>
                  <v-list-item :key="item.name" v-else to="/root/support/amer" dense>
                    <!-- <template> -->

                    <template v-slot:default="{ active }">
                      <v-list-item-avatar>
                        <v-badge
                          bordered
                          bottom
                          color="primary"
                          dot
                          offset-x="15"
                          offset-y="11"
                        >
                          <v-avatar size="35" v-if="item.messages[0].author">
                            <v-img
                              :src="
                                item.messages[0].author.photo != null
                                  ? `${apiUrlAmer}/images/user/${item.messages[0].author.photo}`
                                  : '/profile.png'
                              "
                            ></v-img>
                          </v-avatar>
                          <v-avatar size="35" v-else>
                            <v-img
                              :src="
                                item.messages[0].to.photo != null
                                  ? `${apiUrlAmer}/images/user/${item.messages[0].to.photo}`
                                  : '/profile.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            item.messages[0].author
                              ? getUserName(item.messages[0].author.name)
                              : getUserName(item.messages[0].to.name)
                          "
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.messages[item.messages.length - 1].body"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-badge
                          color="red"
                          :content="
                            countReads(item.messages)
                              ? countReads(item.messages)
                              : 0
                          "
                          bordered
                          overlap
                          v-if="countReads(item.messages)"
                        >
                          <v-icon v-if="!active" color="grey">
                            mdi-chat
                          </v-icon>

                          <v-icon v-else color="grey"> mdi-chat </v-icon>
                        </v-badge>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < chatsAmer.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
              <!-- <v-divider class="py-1"></v-divider> -->
              <!-- <v-list-item to="/root/support">
                <v-list-item-content>
                  <v-btn block color="primary" outlined>
                    Ver todas notificações
                  </v-btn>
                </v-list-item-content>
              </v-list-item>  -->
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <v-avatar size="22" item>
              <img
                v-if="currentUser.photo"
                :src="`${apiUrl}/images/user/${currentUser.photo}`"
              />
              <v-icon v-else class="primary--text">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="
                    currentUser.photo
                      ? `${apiUrl}/images/user/${currentUser.photo}`
                      : `/avatar.png`
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentUser.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ currentUser.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item router to="/profile/user">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>Terminar sessão</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon v-on="on" color="primary">settings</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(setting, i) in settings"
            :key="i"
            route
            :to="setting.route"
            :exact="setting.exact"
          >
            <v-list-item-action>
              <v-icon small>{{ setting.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ setting.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { API_URL, API_URL_KENMARE, API_URL_AMER } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { USER_QUERY } from "./../../auth/graphql/user";
import { mapActions, mapGetters } from "vuex";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../modules/support/graphql/subscription";
import { CHATS_ROOM_QUERY_AMER } from "./../modules/support/graphql/amer/Query";
import { CHATS_ROOM_QUERY_KEN } from "./../modules/support/graphql/kenmare/Query";
import { CHATS_ROOM_QUERY } from "./../modules/support/graphql/Query";
import { SEND_STATUS } from "./../graphql/Query.resolver";
export default {
  name: "AppToolbar",
  props: {
    show: Boolean,
  },
  model: {
    prop: "show",
    event: "hide",
  },
  data: () => ({
    apiUrl: API_URL,
    apiUrlKen: API_URL_KENMARE,
    apiUrlAmer: API_URL_AMER,
    currentFlag: "pt",
    showLogoutDialog: false,
    user: null,
    notifications: 0,
    chats: [],
    chatsKenmare: [],
    chatsAmer: [],
    selected: [2],
    loadMessages: false,
    sound: { soundurl: "/whirelabRing.wav" },
    flag: 2,
  }),
  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "no-cache",
    },
    recivedChats: {
      query: CHATS_ROOM_QUERY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          this.loadMessages = true;
          console.log("recivedChats toolBar wl", data.recivedChats);
          this.chats = data.recivedChats.filter((e) => e.from === "Whirelab");
          this.chats.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
          // this.playSound();
        }
      },
    },
    recivedChats_K: {
      query: CHATS_ROOM_QUERY_KEN,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats_k) {
          this.loadMessages = true;
          console.log("recivedChats toolBar kenmanr", data.recivedChats_k);
          this.chatsKenmare = data.recivedChats_k.filter(
            (e) => e.from === "Kenmare"
          );
          // console.log("this.chatsAmer", this.chatsAmer);
          this.chatsKenmare.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
        }
      },
    },
    recivedChats_A: {
      query: CHATS_ROOM_QUERY_AMER,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats_A) {
          this.loadMessages = true;
          console.log("recivedChats toolBar amer", data.recivedChats_A);
          this.chatsAmer = data.recivedChats_A.filter((e) => e.from === "Amer");
          // console.log("this.chatsAmer", this.chatsAmer);
          this.chatsAmer.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          console.log("nootifique=>>>>>AAAA", data.addChat.from);
          if (data.addChat.flag === "Suport") {
            this.playSound();
            if (data.addChat.from === "Kenmare") {
               this.notifications = 0;
              if (this.notifications === 0) {
                this.notifications = 0;
                this.getMessageKenmare();
                this.getMessageAmer();
                this.getMessage();
              }
            } else if (data.addChat.from === "Amer") {
              this.notifications = 0;
             if (this.notifications === 0) {
                this.notifications = 0;
                this.getMessageAmer();
                this.getMessageKenmare();
                this.getMessage();
              }
            } else if (data.addChat.from === null) {
              this.notifications = 0;
             if(this.notifications === 0) {
                this.notifications = 0;
                this.getMessage();
                this.getMessageAmer();
                this.getMessageKenmare();
              }
            }
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          console.log("upd...", data);
          if (data.updateCountChat.flag === "Suport") {
            if (data.updateCountChat.from === "Kenmare") {
              this.notifications = 0;
              if (this.notifications === 0) {
                this.notifications = 0;
                this.getMessageKenmare();
                this.getMessageAmer();
                this.getMessage();
              }
            } else if (data.updateCountChat.from === "Amer") {
              this.notifications = 0;
              if (this.notifications === 0) {
                this.notifications = 0;
                this.getMessageAmer();
                this.getMessageKenmare();
                this.getMessage();
              }
            } else if (data.updateCountChat.from === "Whirelab") {
              this.notifications = 0;
              if (this.notifications === 0) {
                this.notifications = 0;
                this.getMessage();
                this.getMessageAmer();
                this.getMessageKenmare();
              }
            }
          }
        },
      },
    },
  },
  watch: {
    user: function (val) {
      this.setUserState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    languagess: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    settings: function () {
      return [
        {
          icon: "mdi-key-variant",
          title: "Nível de Acesso",
          route: "/root/root/roles",
          exact: true,
        },
        {
          icon: "mdi-key-variant",
          title: "Nível de Acesso Parceiro",
          route: "/root/root/partner_roles",
          exact: true,
        },
        {
          icon: "mdi-account-group",
          title: "Usuários",
          route: "/root/root/users",
          exact: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    async playSound() {
      var audio = new Audio("/whirelabRing.wav");
      audio.muted = false;
      audio.autoplay = true;
      audio.load();
      var playPromise = audio.play();
      console.log(playPromise);
      if (playPromise !== undefined) {
        await playPromise
          .then((_) => {
            console.log("play", _);
            audio.play();
          })
          .catch((error) => {
            console.log("err", error);
          });
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;
    },
    logout() {
      this.logoutPropreties();
      this.leaving();
    },
    logoutPropreties() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
    },
    countReads(messages) {
      // console.log(messages);
      if (messages[0].author) {
        return messages.filter(
          (m) => m.read === "1" && m.author.id != this.currentUser.id
        ).length;
      } else {
        return messages.filter(
          (m) => m.read === "1" && m.to.id === this.currentUser.id
        ).length;
      }
    },

    initSuport() {
      this.$router.push("/root/support/chat");
    },
    initSuportChat(valor) {
      this.$emit("initChatSuport", valor);
    },
    async leaving() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
        },
      });
    },
    async backOnline() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Online",
        },
      });
    },
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },

    async getMessage() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.recivedChats) {
            // this.chats = res.data.recivedChats;
            this.chats = res.data.recivedChats.filter(
              (e) => e.from === "Whirelab"
            );
            if (this.notifications === 0) {
              this.chats.forEach((element) => {
                this.notifications += this.countReads(element.messages);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMessageAmer() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY_AMER,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          console.log("amer", res.data.recivedChats_A);

          if (res.data.recivedChats_A) {
            this.chatsAmer = res.data.recivedChats_A.filter(
              (e) => e.from === "Amer"
            );

            this.chatsAmer.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMessageKenmare() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY_KEN,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          console.log("Kenmare", res.data.recivedChats_k);
          if (res.data.recivedChats_k) {
            this.chatsKenmare = res.data.recivedChats_k.filter(
              (e) => e.from === "Kenmare"
            );

            this.chatsKenmare.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    window.addEventListener("beforeunload", this.leaving);
    this.$i18n.locale = "pt";
    this.currentFlag = "pt";
  },
  mounted() {
    setTimeout(() => {
      this.backOnline();
    }, 1000);
  },
};
</script>
